<template>
  <div class="funnel-item-container">
    <div
      class="funnel-item"
      :style="{
        width: `${500 - 2 * (index + 1) * borderWidth - 2 * (index + 1) * borderWidth * widthratio}px`,
        borderColor: `${color} transparent transparent transparent`,
        borderWidth: `${borderWidth * 2}px ${borderWidth}px 0 ${borderWidth}px`
      }"
    >
      <div class="value" :style="{ marginTop: `${-borderWidth * 2}px` }">
        <p class="title">{{ title }}</p>
        <p class="number">{{ numThousand(count) }}</p>
      </div>
    </div>
    <div
      class="funnel-item-button"
      v-if="index !== 3 && index + 1 !== length"
      :style="{
        width: `${
          500 - 2 * (index + 1) * borderWidth - 2 * (index + 1) * borderWidth * widthratio - borderWidth * 2
        }px`,
        borderColor: `${color} transparent transparent transparent`,
        borderWidth: `${borderWidth * widthratio * 2}px ${borderWidth * widthratio}px 0 ${borderWidth * widthratio}px`
      }"
    >
      <div
        class="value"
        :style="{ marginTop: `${-borderWidth * widthratio * 2}px` }"
        v-if="(ratio || ratio === 0) && (chain || ratio === 0)"
      >
        <span class="ratio-title">{{ ratioTitle }}：</span>
        <span class="rotio">{{ ratio }}% </span>
        <span :class="{ 'chain-title': true, rise: chain > 0, decline: chain < 0 }">环比：</span>
        <span
          :class="{ chain: true, rise: chain > 0, decline: chain < 0 }"
        >{{ chain > 0 ? '+' : '' }}{{ chain }}%</span
        >
      </div>
      <div class="value" v-else :style="{ marginTop: `${-borderWidth * widthratio * 2}px` }">--</div>
    </div>
  </div>
</template>

<script>
import { numThousand } from '@/utils/dealNumber'
export default {
  data () {
    return {
      borderWidth: 25,
      widthratio: 0.5
    }
  },
  props: {
    index: {
      default: 0,
      type: Number
    },
    color: {
      default: '#fff',
      type: String
    },
    length: {
      default: 0,
      type: Number
    },
    title: {
      default: '',
      type: String
    },
    count: {
      default: 0,
      type: Number
    },
    ratioTitle: {
      default: '',
      type: String
    },
    ratio: {
      default: 0,
      type: [Number, String]
    },
    chain: {
      default: 0,
      type: [Number, String]
    }
  },
  methods: {
    numThousand
  }
}
</script>

<style lang="less" scoped>
.funnel-item-container {
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .funnel-item {
    box-sizing: border-box;
    height: 0;
    border-style: solid;
    padding-bottom: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      transform: scale(1.1, 1);
    }
    .value p {
      padding: 0;
      margin: 0;
      line-height: 20px;
      text-align: center;
      &.title {
        font-size: 12px;
      }
      &.number {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .funnel-item-button {
    box-sizing: border-box;
    height: 0;
    border-style: solid;
    padding-bottom: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      transform: scale(1.1, 1);
    }
    .value {
      font-size: 12px;
      span {
        &.ratio-title {
          font-weight: 600;
        }
        &.decline {
          color: #27ca8a;
        }
        &.rise {
          color: #f02388;
        }
      }
    }
  }
}
</style>
